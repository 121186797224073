import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-description',
  templateUrl: './total-description.component.html',
  styleUrls: ['./total-description.component.scss']
})
export class TotalDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
