import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wifi-description',
  templateUrl: './wifi-description.component.html',
  styleUrls: ['./wifi-description.component.scss']
})
export class WifiDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
