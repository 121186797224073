<div class="description">

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Artikli u korpi</div>
      </div>
      <div class="sectionDetails">Ukoliko želite izmenite artikle u korpi klikom na njih. Možete i da obrišete artikle iz korpe.</div>
    </div>

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Poručivanje</div>
      </div>
      <div class="sectionDetails">Pre poručivanja možete dodati napomenu.</div>
    </div>
  
  </div>