import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppVersionCheckService {
  autoUpdate = false;

  constructor(private updates: SwUpdate, snackBar: MatSnackBar) {

    if (updates.isEnabled) {
      updates.versionUpdates.subscribe(
        e => {
          if (e.type == "VERSION_READY") {
            if (this.autoUpdate) {
              this.update();
            }
            else {
              this.createPopup();
            }
          }
        }
      )

      interval(10 * 60 * 1000).subscribe(() => this.checkForUpdates());  // update sw na 10 min
    }
  }

  checkForUpdates() {
    this.updates.checkForUpdate();
  }

  update() {
    this.updates.activateUpdate().then(
      () => document.location.reload()
    )
  }

  createPopup() {
    var el = document.getElementById("newVersionFound");
    if (!el) {
      el = document.createElement("div");
      el.id = 'newVersionFound';
      el.innerHTML = '<div style="display: flex; cursor: pointer; position: absolute; left: 10px; bottom: 10px; z-index: 10000; border-radius: 5px; overflow: hidden; padding: 15px; background-color: black; color: #e0e0e0">' +
        '<img src="/assets/icons/icon-192x192_white.png" style="height: 46px; margin-right: 10px"/>' +
        '<div>' +
        '<div style="font-weight: bold; margin-bottom: 10px">' +
        'Detektovana je nova verzija aplikacije!' +
        '</div>' +
        'Kliknite ovde za aktivaciju ili osvežite stranicu' +
        '</div>' +
        '</div>';

      el.onclick = (e) => { this.update() };

      document.body.appendChild(el);
    }
  }
}
