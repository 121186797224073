<div class="description">

    <div class="section">
        <div class="title">
            <div class="icon"></div>
            <div class="titleText">Unos porudžbine</div>
        </div>
        <div class="sectionDetails">Artikli su organizovani po grupama, a moguća je i njihova pretraga. Jednostavno
            izmenite količinu, dodajte modifikator ili napomenu klikom na artikal u porudžbini. Porudžbinu možete da
            izdate i za određenu grupu na stolu.</div>
    </div>

    <div class="section">
        <div class="title">
            <div class="icon"></div>
            <div class="titleText">Pregled porudžbina za stolom</div>
        </div>
        <div class="sectionDetails">Pogledajte sve porudžbine kreirane za jednim stolom.</div>
    </div>

    <div class="section">
        <div class="title">
            <div class="icon"></div>
            <div class="titleText">Naplata</div>
        </div>
        <div class="sectionDetails">Izvršite naplatu direktno iz Konobar aplikacije koristeći načine naplate
            definisane u eBar-u.</div>
    </div>
</div>