import { Pipe, PipeTransform } from "@angular/core";
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Product } from "../_interface/product";
import { SessionService } from "../_services/session.service";

@Pipe({ name: "productsInGroup" })
export class ProductsInGroupPipe implements PipeTransform {

  constructor(private session: SessionService) {


  }
  transform(groupID: number | undefined): Observable<Product[]> {

    return this.session.products.pipe(
      map((products) => {
        const group = this.session.groups.value.find(g => g.groupID == groupID);
        if (!group) {
          return [];
        }

        return products.filter(p => group?.productIDs.some((pid: number) => pid == p.productID))
          .sort((a, b) => group.productIDs.indexOf(a.productID) - group.productIDs.indexOf(b.productID));

      })
    );
  }
}
