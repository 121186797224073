<div>
  <mat-form-field>
    <mat-label>Jezik</mat-label>
    <mat-select #selectedTranslation [value]="availableTranslations[0].code">
      <mat-option
        *ngFor="let item of availableTranslations"
        [value]="item.code"
        >{{ item.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
<ng-container [formGroup]="getTranslation(selectedTranslation.value)">
  <div>
    <mat-form-field>
      <mat-label>Naziv</mat-label>
      <input
        matInput
        autocomplete="off"
        #productName
        maxlength="50"
        formControlName="name"
        type="text"
      />
      <mat-hint align="end">{{ productName.value.length }}/50</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Opis</mat-label>
      <textarea matInput #description class="description" formControlName="description"></textarea>
      <mat-hint align="end">{{ description.value.length }}/2000</mat-hint>
    </mat-form-field>
  </div>
</ng-container>
