import { MatIconModule } from '@angular/material/icon';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WaiterComponent } from './waiter/main/waiter.component';
import { UserAppComponent } from './user-app/main/user-app.component';
import { MainComponent } from './main/main.component';
import { MainDescriptionComponent } from './waiter/main-description/main-description.component';
import { TablesDescriptionComponent } from './waiter/tables-description/tables-description.component';
import { OrdersDescriptionComponent } from './waiter/orders-description/orders-description.component';
import { TotalDescriptionComponent } from './user-app/total-description/total-description.component';
import { OrderItemDescriptionComponent } from './user-app/order-item-description/order-item-description.component';
import { WifiDescriptionComponent } from './user-app/wifi-description/wifi-description.component';
import { HistoryDescriptionComponent } from './user-app/history-description/history-description.component';
import { ShoppingBagDescriptionComponent } from './user-app/shopping-bag-description/shopping-bag-description.component';
import { OptionsMenuDescriptionComponent } from './user-app/options-menu-description/options-menu-description.component';
import { MenuDescriptionComponent } from './user-app/menu-description/menu-description.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button'
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { LoginQRComponent } from './waiter/login-qr/login-qr.component';
import { QrCodeModule } from 'ng-qrcode';
import { LoginComponent } from './login/login.component';
import { AuthorizationService } from './core/_services/authorization.service';
import { SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { ClickHereAnimationComponent } from './click-here-animation/click-here-animation.component';
import { IframeTrackerDirectiveDirective } from './_directives/iframe-tracker-directive.directive';


const config: SocialAuthServiceConfig = {
  providers: [

    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleClientID, {
        oneTapEnabled: false
      }),
    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookAppID)
    }]
};


export function provideConfig() {
  return config;
}

export function tokenGetter() {
  return localStorage.getItem('authToken');
}

export function jwtOptionsFactory(inject: Injector) {


  return {
    tokenGetter: () => {
      const auth = inject.get(AuthorizationService);
      return auth?.getToken();
    },
    allowedDomains: [
      environment.host
    ]
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WaiterComponent,
    UserAppComponent,
    MainComponent,
    MainDescriptionComponent,
    TablesDescriptionComponent,
    OrdersDescriptionComponent,
    TotalDescriptionComponent,
    OrderItemDescriptionComponent,
    WifiDescriptionComponent,
    HistoryDescriptionComponent,
    ShoppingBagDescriptionComponent,
    OptionsMenuDescriptionComponent,
    MenuDescriptionComponent,
    LoginQRComponent,
    LoginComponent,
    ClickHereAnimationComponent,
    IframeTrackerDirectiveDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    CoreModule,
    QrCodeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately'
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Injector]
      }
    })
  ],
  providers: [
    { provide: 'SocialAuthServiceConfig', useFactory: provideConfig },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(jwt: JwtHelperService, inject: Injector) {
    // circual depencency problem
    // kreiramo JWT service pre prvog requesta kako bi se isti uspešno povezao na dependecy service AuthServiceService


    inject.get(AuthorizationService);
  }

}
