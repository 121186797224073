import { Order } from 'src/app/core/_interface/order';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortOrdersByDate'
})
export class SortOrdersByDatePipe implements PipeTransform {

  transform(orders: Order[] | null, descending = 0) {
    if (!orders) {
      return null;
    }

    if (descending) {
      return orders.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }

    return orders.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());

  }

}
