<div class="property">
  <span class="name">{{ name }}</span>
  <div class="value">
    <ng-container
      [ngTemplateOutlet]="valueTemplate ?? defaultValueTemplate"
      [ngTemplateOutletContext]="{ value: value }"
    ></ng-container>
  </div>
</div>
<ng-template #defaultValueTemplate>
  <ng-container *ngIf="value !== undefined; else content">{{
    value
  }}</ng-container>
</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
