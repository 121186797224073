import { debounce, debounceTime, delay, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, HostListener } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { PostMessageListennerService } from 'src/app/_services/post-message-listenner.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-waiter',
  templateUrl: './waiter.component.html',
  styleUrls: ['./waiter.component.scss']
})
export class WaiterComponent implements OnDestroy {
  page = "";
  subcription: Subscription;
  userActive = false;
  moveSubject = new Subject<void>();
  destroy = new Subject<void>();

  waiterApp?: SafeResourceUrl;

  @ViewChild("desc") desc?: any;

  constructor(private postListenner: PostMessageListennerService,
    private sanitizer: DomSanitizer) {
    var url = environment.backendBase;
    this.waiterApp = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace("app.", "konobar."));

    this.subcription = postListenner.messages.subscribe(
      msg => {
        this.page = msg.name;
      }
    )

    this.moveSubject.pipe(
      takeUntil(this.destroy),
      debounceTime(30000)
    ).subscribe(
      () => this.userActive = false
    )
  }


  ngOnDestroy(): void {
    this.subcription.unsubscribe();

    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousemove', ['$event'])
  setActive() {
    this.userActive = true;
    this.moveSubject.next();
  }


}
