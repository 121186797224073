<div class="description">

    <div class="section">
        <div class="title">
            <div class="icon"></div>
            <div class="titleText">Pregled stolova</div>
        </div>
        <div class="sectionDetails">U svakom trenutku imate uvid u slobodne stolove, kao i zaduženje po stolovima. Svaki
            konobar može da izabere svoj rejon ili tačno određene stolove koje poslužuje.</div>
    </div>

    <div class="section">
        <div class="title">
            <div class="icon"></div>
            <div class="titleText">Zahtevi gostiju</div>
        </div>
        <div class="sectionDetails">Povećajte zadovoljstvo Vaših gostiju uvidom u to da li neko poziva konobara, traži
            račun ili je napravio novu porudžbinu putem eMeni Korisničke aplikacije.</div>
    </div>

    <div class="section">
        <div class="title">
            <div class="icon"></div>
            <div class="titleText">Telefonska porudžbina?</div>
        </div>
        <div class="sectionDetails">Unesite je putem eMeni Konobar aplikacije kao direktnu porudžbinu (vidi Rejon dugme)
            i ona će odmah biti vidljiva eBar-u.</div>
    </div>

</div>
