import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostMessageListennerService {
  messages = new Subject<any>();

  constructor() {
    window.addEventListener('message', this.handleMessage.bind(this));

  }


  handleMessage(event: any) {
    try {
      var data = JSON.parse(event.data);
      this.messages.next(data);

    } catch (e) {
    }
  }

}
