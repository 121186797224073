export enum EventType {
  ProductViewed = 1,
  WaiterCalled = 2,
  PaymentCardRequested = 3,
  PaymentCacheRequested = 4,
  TableTotalRequested = 5,
  MenuViewed = 6,
  QRCodeScaned = 7,
  ChargeExecuted = 8,
  OrderCreatedWaiter = 9,
  OrderCreatedUser = 10,
  NoResponseFromPOS = 11,
}


export interface EventSummaryItem {
  count: number;
  date: string;
  type: EventType;
}
