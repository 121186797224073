import { filter, first, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { AuthorizationService } from './core/_services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedinGuard implements CanActivate {

  constructor(
    private authService: AuthorizationService, private router: Router, private toast: ToastrService) {

  }
  canActivate(route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot): boolean |
    import('@angular/router').UrlTree |
    import('rxjs').Observable<boolean |
    import('@angular/router').UrlTree> |
    Promise<boolean | import('@angular/router').UrlTree> {

    return this.authService.tokenLoaded.pipe(
      filter(val => val),
      first(),
      switchMap(
        () => {
          if (this.authService.currentUser.value != null) {
            return of(true);
          }

          const urlTree = this.router.createUrlTree(['/login']);
          return of(urlTree);
        }
      )
    );
  }


}
