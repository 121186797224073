import { Router } from '@angular/router';
import { interval, Observable, observable, Subscription } from 'rxjs';
import { CodeSigninService } from './../_services/code-signin.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { SignInCode } from '../_interface/signiCode';

@Component({
  selector: 'app-code-signin',
  templateUrl: './code-signin.component.html',
  styleUrls: ['./code-signin.component.scss']
  , encapsulation: ViewEncapsulation.None
})
export class CodeSigninComponent implements OnInit, OnDestroy {

  loginTimer?: Subscription;
  progressbarTimer?: Subscription;
  progressbarValue = 0;
  currentSec = 0;

  @Input()
  successLoginRedirect = '';

  private _code?: SignInCode | undefined;
  public get code(): SignInCode | undefined {
    return this._code;
  }
  public set code(value: SignInCode | undefined) {
    this._code = value;

    this.stopLoginTimer()

    if (value) {

      this.startProgressbarTimer(value.expires);
      this.startLoginTimer();
    }
  }

  constructor(private codeSigninService: CodeSigninService, private router: Router) {

  }
  ngOnDestroy(): void {
    this.stopLoginTimer();
    this.progressbarTimer?.unsubscribe();

  }

  ngOnInit(): void {
    this.generateCode();
  }

  generateCode() {
    this.codeSigninService.generate().subscribe({
      next: code => {
        this.code = code
      },
      error: () => {
        setTimeout(() => {
          this.generateCode();
        }, 5000);
      }
    }
    );
  }

  stopLoginTimer() {
    this.loginTimer?.unsubscribe();
    this.loginTimer = undefined;
  }

  startLoginTimer() {
    this.stopLoginTimer();

    if (!this.code) {
      return;
    }

    this.loginTimer = interval(5000).subscribe(() => {
      this.codeSigninService.tryLogin(this.code!.code, this.code!.token).subscribe(
        {
          next: success => {
            if (success) {
              this.router.navigate([this.successLoginRedirect]);

            }
          }

          , error: (e) => { console.log(e) }
        })
    });
  }


  startProgressbarTimer(seconds: number) {
    const time = seconds;
    const timer$ = interval(100);

    this.progressbarTimer?.unsubscribe();
    this.progressbarValue = 100;

    this.progressbarTimer =
      timer$.subscribe((sec) => {
        var val = sec / 10;
        this.progressbarValue = 100 - (val + 1) * 100 / seconds;
        this.currentSec = val;

        if (this.currentSec === seconds) {
          this.progressbarTimer?.unsubscribe();
          this.generateCode();

        }
      });
  }

}
