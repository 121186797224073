import { takeUntil } from 'rxjs/operators';
import { MatKeyboardService } from 'angular-onscreen-material-keyboard';
import { Subject } from 'rxjs';
import { Component, Input, OnInit, Output, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { LoginResult } from '../_interface/apiResponse';
import { AuthorizationService } from '../_services/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { QRCodeScannerComponent } from '../qrcode-scanner/qrcode-scanner.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'core-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  isBusy = false;
  loginForm: UntypedFormGroup;
  environment = environment;
  destroyed = new Subject<void>();
  showLogInForm = false;

  invalidSession = false;

  @Output() loggedIn = new Subject<void>();

  @Input()
  showQRLogin = false;

  @Input()
  successLoginRedirect = '/';

  @Input()
  enableCodeSignin = false;

  handleToken() {

  }
  constructor(private authService: SocialAuthService,
    fb: UntypedFormBuilder,
    public myAuthService: AuthorizationService,
    private router: Router,
    private toast: ToastrService,
    private dialog: MatDialog,
    private ks: MatKeyboardService,
    activatedRoute: ActivatedRoute
  ) {

    this.invalidSession = router.getCurrentNavigation()?.extras.state?.['invalidSession'];

    this.authService.authState.pipe(
      takeUntil(this.destroyed)
    ).subscribe((user) => {
      if (!user) {
        return;
      }

      switch (user.provider) {
        case ('GOOGLE'):
          this.sendGoogleAuthToken(user.idToken);
          break;
        case ('FACEBOOK'):
          this.sendFacebookAuthToken(user.authToken);
          break;
      }
    });

    activatedRoute.params.pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      params => {
        var token = params["token"];
        if (token) {
          myAuthService.loginWithToken(token).subscribe(
            result => {
              this.loginCompleted(result);
            }
          )
        }
      }
    )

    this.loginForm = fb.group({
      'username': '',
      'password': ''
    });

  }
  ngOnDestroy(): void {
    this.ks.dismiss();
    this.destroyed.next();
    this.destroyed.complete();
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // on success
        this.isBusy = true;
        // this will return user data from google. What you need is a user token which you will send it to the server
      });
  }

  sendGoogleAuthToken(token: string) {
    this.myAuthService.googleSignInExternal(token)
      // .pipe(finalize(() => this.isLoading = false))
      .subscribe(result => {
        this.loginCompleted(result);

      });
  }

  signInWithFB(): void {

    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, { enable_profile_selector: true })
      .then((userData) => {
        this.isBusy = true;
        // on success
        // this will return user data from google. What you need is a user token which you will send it to the server
      });
  }

  sendFacebookAuthToken(token: string) {
    this.myAuthService.facebookSignInExternal(token)
      // .pipe(finalize(() => this.isLoading = false))
      .subscribe(result => {
        this.loginCompleted(result);

      });
  }

  signInWithCode() {
    var ref = this.dialog.open(QRCodeScannerComponent);
    var instance = ref.componentInstance;
    var previousData = '';
    instance.scanCompleted.subscribe(
      data => {
        if (previousData == data) {
          return;
        }
        previousData = data;
        instance.enabled = false;

        this.myAuthService.loginWithToken(data).subscribe(
          result => {
            if (result == LoginResult.OK) {
              ref.close();
            }

            instance.enabled = true;

            this.loginCompleted(result);
          }
        )
      }
    )
  }

  logout(): void {
    this.myAuthService.signOut();
  }


  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.isBusy = true;

    this.myAuthService.login(this.loginForm.value).subscribe(
      result => {
        this.loginCompleted(result);
      }
    );
  }

  loginCompleted(result: LoginResult) {
    this.isBusy = false;
    switch (result) {
      case (LoginResult.OK):
        this.loggedIn.next();

        this.router.navigate([this.successLoginRedirect]);
        break;
      case (LoginResult.Disabled):
        this.toast.error('Nalog je zaključan');
        break;
      case (LoginResult.AccessDenied):
        this.toast.error('Pristup zabranjen');
        break;
      case (LoginResult.InvalidToken):
        this.toast.error('Neispravan token');
        break;
      case (LoginResult.InvalidUserName):
        this.toast.error('Pogrešno korisničko ime i/ili šifra');
        break;
    }
  }

}
