<div class="description">

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Zaduženje stola</div>
      </div>
      <div class="sectionDetails">Gost u svakom trenutku može da proveri zaduženje stola i da traži račun.</div>
    </div>
  
  </div>