import { Table, TableTotal } from './../_interface/table';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse, GenericItemApiResponse, GenericListApiResponse } from 'src/app/core/_interface/apiResponse';
import { map, tap } from 'rxjs/operators';
import { ICompany } from 'src/app/core/_interface/company';
import { Payment } from '../_interface/payment';
import { ChargeOrder, ChargeTableResponse } from '../_interface/charge';

@Injectable({
  providedIn: 'root'
})
export class TableService {



  saved = new Subject<Table>();
  deleted = new Subject<Table>();

  constructor(private http: HttpClient) {

  }


  get(companyID: number): Observable<Table[] | undefined> {
    const url = environment.apiBase + `table/get?companyID=${companyID}`;
    return this.http.get<GenericListApiResponse<Table>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return undefined;
        })
      );
  }

  assignedTables(userID: number, companyID: number) {
    const url = environment.apiBase + `table/assignedTables?companyID=${companyID}&userID=${userID}`;
    return this.http.get<GenericListApiResponse<Table>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return undefined;
        })
      );
  }

  assignTables(companyID: number, userID: number, tableIDs: number[]) {
    const url = environment.apiBase + `table/assignTables`;
    var data = {
      companyID: companyID,
      userID: userID,
      tableIDs: tableIDs
    }
    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return response.success;
          }
          throw response;
        })
      );
  }


  save(companyID: number, table: Table): Observable<Table | undefined> {
    const url = environment.apiBase + `table/save?companyID=${companyID}`;
    return this.http.post<GenericItemApiResponse<Table>>(url, table)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          return undefined;
        }),
        tap(s => {
          if (s) {
            this.saved.next(s);
          }
        })
      );
  }


  delete(table: Table): Observable<boolean> {
    const url = environment.apiBase + `table/delete`;
    return this.http.post<ApiResponse>(url, table)
      .pipe(
        map(response => response.success),
        tap(s => this.deleted.next(table))
      );
  }

  getTotal(companyID: number, tableID: number) {

    const url = environment.apiBase + `table/total?companyID=${companyID}&tableID=${tableID}`;

    return this.http.get<GenericItemApiResponse<TableTotal>>(url).pipe(
      map(
        data => {
          if (data.success) {
            return data.item;
          }
          else {
            throw data.errorCode;
          }
        }
      )
    );
  }


  cancelCallWaiter(tableID: number) {
    const url = environment.apiBase + `table/CancelCallWaiter?tableID=${tableID}`;
    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }

  cancelRequestPayment(tableID: number) {
    const url = environment.apiBase + `table/CancelRequestPayment?tableID=${tableID}`;
    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }

  chargeSingleOrder(orderID: number, payments: Payment[], total: number) {
    const url = environment.apiBase + `table/chargeSingleOrder`;
    var data: ChargeOrder = {
      orderID: orderID,
      payments: payments,
      total: total
    };

    return this.http.post<GenericItemApiResponse<ChargeTableResponse>>(url, data).pipe(
      map(val => {
        if (val.success) {
          return val.item;
        }

        throw val.errorDescription;
      })
    );
  }

}
