import { BeepService } from './beep.service';
import { WSService } from 'src/app/core/_services/ws.service';
import { OrderService } from 'src/app/core/_services/order.service';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { vapidKey } from 'src/environments/vapid';
import { ApiResponse } from '../_interface/apiResponse';
import { OrderStatus } from '../_interface/order';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private swPush: SwPush,
    private beepService: BeepService,
    private http: HttpClient,
    tableService: TableService,
    ws: WSService,
    orderService: OrderService) {

    swPush.messages.subscribe({
      next: (msg: any) => {
        /*
                if (msg.notification.tag.startsWith("callWaiter_")) {
                  if (!msg.notification.data.called) {
                    this.removeCallWaiterNotification(msg.notification.data.tableID);
                  } else {
                    this.playAudio();
                  }
                } else {
                  */
        this.beepService.playWarning();
        /*
      }
      */
      }
    });

    ws.observe("table").pipe(
    ).subscribe(
      received => {
        var msg = received.data;

        if (msg.action == "waiterCalled") {
          var tableID = msg.tableID;

          if (!msg.called) {
            this.removeNotification("callWaiter_" + tableID);
          }
        }
      }
    );

    ws.observe("orderStatusChanged").pipe(
    ).subscribe(
      received => {
        var msg = received.data;


        var orderID = msg.orderID;

        if (!msg.called) {
          this.removeNotification("orderCreated_" + orderID);
        }

      }
    );


    this.swPush.notificationClicks.subscribe(
      ({ action, notification }) => {
        if (action == "confirm") {
          var tableID = notification.data.tableID;
          tableService.cancelCallWaiter(tableID).subscribe();
        } else if (action == "accept") {
          var orderID = notification.data.orderID;
          orderService.setStatus(orderID, OrderStatus.Accepted).subscribe();
        } else if (action == "reject") {
          var orderID = notification.data.orderID;
          orderService.setStatus(orderID, OrderStatus.Rejected).subscribe();
        }
      });


  }

  private removeNotification(tag: string) {
    navigator.serviceWorker.ready.then(function (registration) {
      registration.getNotifications({ tag: tag }).then(
        notifications => {
          notifications.forEach(n => n.close());
        }
      );
    });
  }

  getSubscription(): Observable<PushSubscription | null> {
    if (!this.swPush.isEnabled) {
      return of(null);
    }

    return from(this.swPush.requestSubscription({
      serverPublicKey: vapidKey
    }).then(sub => {
      return sub;
      //      console.log(sub);
    })
    );
  }

  get isEnabled(): boolean {
    return this.swPush.isEnabled;
  }

  subscribe(subscription: PushSubscription, target: number) {

    const url = environment.apiBase + `notification/subscribe`;
    var data = {
      subscription: subscription.toJSON(),
      target: target
    }

    return this.http.post<ApiResponse>(url, data).subscribe();
  }

  subscribeWaiter(subscription: PushSubscription, companyID: number) {

    const url = environment.apiBase + `notification/subscribe`;
    var data = {
      subscription: subscription.toJSON(),
      target: 2,
      companyID: companyID
    }

    return this.http.post<ApiResponse>(url, data).subscribe();
  }

  unsubscribe() {
    this.swPush.unsubscribe();
  }


}
