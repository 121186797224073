<div class="description">
  <h1>eMeni Konobar</h1>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">
        Koristite eMeni Konobar aplikaciju bez instalacije i na bilo kom uređaju
      </div>
    </div>
    <div class="sectionDetails">
      Našu konobar aplikaciju možete da koristite na bilo kom uređaju! Dovoljno
      je samo da uređaj ima aktivnu internet konekciju i pretraživač. Ovo
      uključuje Android i iPhone uređaje, tablete, laptopove, …
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Nije neophodan Wi-Fi u lokalu</div>
    </div>
    <div class="sectionDetails">
      eMeni Konobar aplikacija radi preko bilo koje internet konekcije,
      uključujući i mobilni internet. Niste vezani za korišćenje sopstvene Wi-Fi
      mreže.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Ulogujte se skeniranjem QR koda</div>
    </div>
    <div class="sectionDetails">
      Konobari mogu da koriste postojeće naloge iz eBar-a i prijave se na eMeni
      Konobar aplikaciju jednostavnim skeniranjem QR koda. Razduživanjem u
      eBar-u konobar se odjavljuje sa aplikacije.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">
        Uzmite porudžbinu za stolom, unesite modifikatore i napomene
      </div>
    </div>
    <div class="sectionDetails">
      Ubrzajte uslugu u svom objektu i smanjite mogućnost greške unošenjem
      porudžbine za stolom. Proizvodi su grupisani na isti način kao u eBar-u,
      imaju iste modifikatore i omogućen je slobodan unos modifikatora.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Naplatite iz eMeni Konobar aplikacije</div>
    </div>
    <div class="sectionDetails">
      U svakom trenutku možete da vidite zaduženje svakog stola i da izdate
      račun direktno iz eMeni Konobar aplikacije.
    </div>
  </div>
</div>
<div class="webApp">
  <iframe appIframeTrackerDirective (iframeClick)="setActive()" [src]="waiterApp"></iframe>
  <div class="okvir"></div>
  <app-click-here-animation *ngIf="!userActive"></app-click-here-animation>
</div>
<div class="description">
  <ng-container [ngSwitch]="page">
    <app-orders-description *ngSwitchCase="'order'"></app-orders-description>
    <app-tables-description *ngSwitchCase="'tables'"></app-tables-description>
    <app-main-description *ngSwitchCase="''" #desc></app-main-description>
  </ng-container>
  <app-login-qr class="loginQRcode"></app-login-qr>
</div>