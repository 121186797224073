export interface ApiResponse {
  success: boolean;
  errorCode: number;
  errorDescription: string;
}

export interface GenericItemApiResponse<T> extends ApiResponse {
  item: T;
}

export interface GenericListApiResponse<T> extends ApiResponse {
  items: T[];
}

export enum LoginResult {
  AccessDenied,
  InvalidUserName,
  Disabled,
  OK,
  InvalidToken
}

export enum ErrorCodes {
  ProductNotFound = 1,
  AccessDenied = 2,
  InvalidCompany = 3,
  SubscriptionExpired = 4,
  InvalidOperation = 5,
  InvalidToken = 6,
  UserNotFound = 7,
  InvalidResetPasswordToken = 8,
  InvalidPassword = 9,
  InvalidUserNamePassword = 10,
  InvalidBundle = 11,
  UserDisabled = 12,
  CompanyAlreadyRegistered = 13,
  CompanyNotFound = 14,
  MailAlreadyRegistered = 15,
  InvalidApiKey = 16,
  InvalidInvoice = 17,
  AdminOnly = 18,
  NotLoggedIn = 19,
  InvoiceNotFound = 20,
  AlreadyExtended = 21,
  InvalidProduct = 22,
  FileSizeTooBig = 23,
  InvalidSuggestion = 24,
  InvalidOrder = 25,
  InvalidModifier = 26,
  QRCodeTaken = 30,
  TooManyGlobalQRCodes = 31,
  InvalidIPAddress = 32,

  InvalidSession = 38,
  NoResponseFromPOS = 42,
  InvalidSigniture = 43,
  InvalidPrice = 44,
  InvalidCode = 45,
}
