
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Payment, PaymentType } from '../_interface/payment';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss']
})
export class ChargeComponent implements OnInit {
  PaymentMethod = PaymentType;
  amount: number = 0;
  remaining = 0;

  selectedPaments: Payment[] = [];

  @Output() paymentsSelected = new EventEmitter<Payment[]>();

  @ViewChild("amountField") amountField?: ElementRef;

  @Input() totalAmount: number = 0;

  constructor(
    private dialogRef: MatDialogRef<ChargeComponent>
  ) {


   }

  ngOnInit(): void {
    this.updateAmount();
  }

  updateAmount() {
    var selectedAmount = this.selectedPaments.reduce((p, c) => p + c.amount, 0);

    this.amount = this.totalAmount - selectedAmount;
    this.amount = Math.round((this.amount + Number.EPSILON) * 100) / 100
    this.remaining = this.amount;



    if (this.amount <= 0) {
      this.sendCharge();
    }
  }

  sendCharge() {
    this.paymentsSelected.next(this.selectedPaments);
    this.dialogRef.close();
  }

  addPayment(type: PaymentType) {
    if (this.amount > 0) {
      var p: Payment = {
        amount: this.amount,
        type: type
      };

      this.selectedPaments.push(p);
    }

    this.updateAmount();
  }

  voidOrders(type: PaymentType) {
    var p: Payment = {
      amount: this.totalAmount,
      type: type
    };

    this.selectedPaments = [p];

    this.updateAmount();

  }
}
