import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericListApiResponse, GenericItemApiResponse, ApiResponse } from 'src/app/core/_interface/apiResponse';
import { Product } from 'src/app/core/_interface/product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  productSaved = new Subject<Product>();
  productsDeleted = new Subject<number[]>();

  constructor(private http: HttpClient) {

  }


  get(companyID: number): Observable<Product[] | undefined> {
    const url = environment.apiBase + `product/get?companyID=${companyID}`;
    return this.http.get<GenericListApiResponse<Product>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return undefined;
        })
      );
  }

  getSingleProduct(companyID: number, productID: number): Observable<Product | undefined> {
    const url = environment.apiBase + `product/getSingleProduct?companyID=${companyID}&productID=${productID}`;
    return this.http.get<GenericItemApiResponse<Product>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          return undefined;
        })
      );
  }
  save(companyID: number, product: Product): Observable<Product | undefined> {
    const url = environment.apiBase + `product/save?companyID=${companyID}`;
    return this.http.post<GenericItemApiResponse<Product>>(url, product)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          return undefined;
        }),
        tap(p => {
          if (p) {
            this.productSaved.next(p)
          }
        }
        )
      );
  }

  delete(companyID: number, productIDs: number[]) {
    const url = environment.apiBase + `product/delete?companyID=${companyID}`;
    return this.http.post<ApiResponse>(url, productIDs)
      .pipe(
        map(response => {
          return response.success;
        }),
        tap(p => this.productsDeleted.next(productIDs))
      );
  }
  uploadImage(productID: number, file: File): Observable<boolean> {
    const url = environment.apiBase + 'product/uploadImage?productID=' + productID;

    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    return this.http.post<ApiResponse>(url, formData)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }

  deleteImage(productID: number) {
    const url = environment.apiBase + 'product/DeleteImage?productID=' + productID;

    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }

  moveProductToGroup(companyID: number, productIDs: number[], newGroupID: number, keepGroups: boolean): Observable<boolean> {
    const url = environment.apiBase + `product/AssignGroup`;
    var data = {
      companyID: companyID,
      productIDs: productIDs,
      newGroupID: newGroupID,
      keepGroups: keepGroups
    };

    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }

  setIsAvailable(productID: number, isAvailable: boolean) {
    const url = environment.apiBase + `product/SetIsAvailable?productID=${productID}&isAvailable=${isAvailable}`;

    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          if (!response.success) {
            throw response;
          }
          return response.success;
        })
      );
  }

}
