import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appIframeTrackerDirective]'
})
export class IframeTrackerDirectiveDirective {
  private iframeMouseOver: boolean = false;

  @Input() debug: boolean = false;

  @Output() iframeClick = new EventEmitter<ElementRef>();
  @Output() iframeMove = new EventEmitter<boolean>();

  constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {

  }

  ngOnInit(): void {
    this.renderer.listen(window, 'blur', () => this.onWindowBlur());
  }

  @HostListener('mousemove')
  @HostListener('mouseover')
  private onIframeMouseOver() {
    this.log('Iframe mouse over');
    this.iframeMouseOver = true;
    this.iframeMove.emit(true);
    this.resetFocusOnWindow();
  }

  @HostListener('mouseout')
  private onIframeMouseOut() {
    this.log('Iframe mouse out');
    this.iframeMouseOver = false;
    this.iframeMove.emit(false);

    this.resetFocusOnWindow();
  }

  private onWindowBlur() {
    if (this.iframeMouseOver) {
      this.log('WOW! Iframe click!!!');
      this.resetFocusOnWindow();
      this.iframeClick.emit(this.el);
    }
  }

  private resetFocusOnWindow() {
    setTimeout(() => {
      this.log('reset focus to window');
      window.focus();
    }, 100);
  }

  private log(message: string) {
    if (this.debug) {
      console.log(message);
    }
  }

}
