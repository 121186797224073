<div class="description">
  <h1>Prednosti eMenija</h1>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">
        Usluga vašeg restorana postaje brža i sigurnija
      </div>
    </div>
    <div class="sectionDetails">
      Smanjivanjem neophodne interakcije između vaših konobara i klijenata,
      usluga restorana postaje mnogo brža.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Smanjite troškove poslovanja</div>
    </div>
    <div class="sectionDetails">
      Optimizujte rad vašeg osoblja. Dodatni profit možete iskoristiti za
      povećavanje plata vašeg tima! Svi smo svesni da je sve teže naći obučeno
      osoblje.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Smanjite troškove štampe</div>
    </div>
    <div class="sectionDetails">
      Ne brinite o zameni štampanog menija. Digitalni meni je ključan ako vodite
      restoran koji stalno nudi nova jela ili menja svoj meni često. Recite
      zbogom grafičkom dizajneru i štampariji!
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Lako projektovanje menija</div>
    </div>
    <div class="sectionDetails">
      Eliminisanjem štampanog menija možete menjati digitalni kad god želite.
      Sve promene koje unesete u meni vašeg objekta odmah su vidljive vašim
      gostima. eMeni je dostupan sa bilo koje lokacije u svetu.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Povećajte svoj prihod</div>
    </div>
    <div class="sectionDetails">
      Najlakši način da povećate svoj promet je održavanje izuzetno efikasne
      usluge onda kada je restoran najpuniji.
    </div>
  </div>
</div>

<div class="webApp">
  <iframe
    appIframeTrackerDirective
    (iframeClick)="setActive()"
    [src]="userApp"
  ></iframe>
  <div class="okvir"></div>
  <app-click-here-animation *ngIf="!userActive"></app-click-here-animation>
</div>
<div class="description">
  <span [ngSwitch]="page">
    <app-total-description *ngSwitchCase="'total'"></app-total-description>
    <app-wifi-description *ngSwitchCase="'wifi'"></app-wifi-description>
    <app-history-description
      *ngSwitchCase="'history'"
    ></app-history-description>
    <app-shopping-bag-description
      *ngSwitchCase="'shoppingBag'"
    ></app-shopping-bag-description>
    <app-options-menu-description
      *ngSwitchCase="'optionsMenu'"
    ></app-options-menu-description>
    <app-menu-description *ngSwitchCase="'menu'"></app-menu-description>
    <app-order-item-description
      *ngSwitchCase="'orderItem'"
    ></app-order-item-description>
    <p *ngSwitchDefault></p>
  </span>

    <qr-code [value]="userAppUrl" class="qrCode"></qr-code>
</div>
