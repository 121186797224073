import { CrossDomainStorageService } from './../../core/_services/cross-domain-storage.service';
import { takeUntil } from 'rxjs/operators';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { PostMessageListennerService } from 'src/app/_services/post-message-listenner.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-app',
  templateUrl: './user-app.component.html',
  styleUrls: ['./user-app.component.scss']
})
export class UserAppComponent implements OnDestroy {
  subcription: Subscription;
  userActive = false;
  moveSubject = new Subject<void>();
  destroy = new Subject<void>();

  userAppUrl = '';
  userApp?: SafeResourceUrl;

  page = "";

  constructor(private postListenner: PostMessageListennerService,
    private sanitizer: DomSanitizer,
    crossDomainStorage: CrossDomainStorageService
  ) {
    crossDomainStorage.remove('options_showHint');
    crossDomainStorage.remove("bannerDisplayDate");

    var url = environment.backendBase;
    this.userAppUrl = url + "/q-demo";
    this.userApp = this.sanitizer.bypassSecurityTrustResourceUrl(this.userAppUrl);


    this.subcription = postListenner.messages.subscribe(
      msg => {
        this.page = msg.name;
      }
    )

    this.moveSubject.pipe(
      takeUntil(this.destroy),
      debounceTime(30000)
    ).subscribe(
      () => this.userActive = false
    )
  }

  ngOnDestroy(): void {
    this.subcription.unsubscribe();
    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousemove', ['$event'])
  setActive() {
    this.userActive = true;
    this.moveSubject.next();
  }

}
