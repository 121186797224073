import { Component, Input, OnInit, ElementRef, Optional, Self, Renderer2 } from '@angular/core';
import { NgControl, FormControl, AbstractControl } from '@angular/forms';
import { MatKeyboardService } from 'angular-onscreen-material-keyboard';

@Component({
  selector: 'app-virtual-keyboard',
  templateUrl: './virtual-keyboard.component.html',
  styleUrls: ['./virtual-keyboard.component.scss']
})
export class VirtualKeyboardComponent implements OnInit {
  @Input() inputElement?: HTMLInputElement;
  @Input() control: AbstractControl | null = null;

  constructor(private ks: MatKeyboardService,
    private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  toggleKeyboard() {
    if (this.ks.isOpened) {
      this.ks.dismiss();
    }
    else {
      if (this.inputElement) {
        var ref = this.ks.open();
        var d = new ElementRef(this.inputElement);
        ref.instance.setInputInstance(d);

        if (this.control) {
          ref.instance.attachControl(this.control);
        }

        var ev = this.renderer.listen(this.inputElement, "blur", () => {
          this.ks.dismiss();
          ev();
        });

      }
    }
  }
}
;
