import { FilterOrdersByStatusPipe } from './_pipes/filter-orders.pipe';
import { EventNamePipe } from './_pipes/event-name.pipe';
import { RouterModule } from '@angular/router';
import { GoogleSigninButtonDirectiveDirective } from './_directives/google-signin-button-directive.directive';
import { ToDeliveryTypeNamePipe } from './_pipes/ToDeliveryTypeName.pipe';
import { GroupBoxComponent } from './group-box/group-box.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { FullNamePipe } from './_pipes/full-name.pipe';
import { ToProductPipe } from './_pipes/ToProduct.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { AppVersionCheckService } from './_services/app-version-check.service';
import { HttpClientModule } from '@angular/common/http';
import { ProductsInGroupPipe } from './_pipes/productsInGroup.pipe';
import { ToAllergenNamePipe } from './_pipes/ToAllergenName.pipe';
import { ToAllergenClassPipe } from './_pipes/ToAllergenClass.pipe';
import { AccessDeniedInterceptor } from './accessDeniedInterceptor';

import { TranslationComponent } from './translation/translation.component';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NameTranslationEditComponent } from './name-translation-edit/name-translation-edit.component';
import { OrderStatusPipe } from './_pipes/orderStatus.pipe';
import { EnumCheckboxSelectorComponent } from './enum-checkbox-selector/enum-checkbox-selector.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { EnumEnumeratorPipe } from './_pipes/enumEnumerator.pipe';
import { HasPermissionPipe } from './_pipes/hasPermission.pipe';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IsBusyDirective } from './_directives/is-busy.directive';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { ToBundleNamePipe } from './_pipes/ToBundleName.pipe';
import { QRCodeScannerComponent } from './qrcode-scanner/qrcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InViewDirective } from './_directives/inView.directive';
import { GetProductByIDPipe } from './_pipes/getProductByID.pipe';
import { ParentProductPipe } from './_pipes/parentProduct.pipe';
import { GroupOrderItemModifiersPipe } from './_pipes/groupOrderItemModifiers.pipe';
import { OrderItemDetailsComponent } from './order-item-details/order-item-details.component';
import { ToGroupIconClassPipe } from './_pipes/ToGroupIconClass.pipe';
import { ChildProductsPipe } from './_pipes/ChildProducts.pipe';
import { ToProductsPipe } from './_pipes/ToProducts.pipe';
import { SortPipe } from './_pipes/sort.pipe';
import { AuthTokenLoadedResolver } from './_helpers/auth-token-loaded-resolver';
import { RouteLoadingComponent } from './route-loading/route-loading.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { OrderStatusSelectorComponent } from './order-status-selector/order-status-selector.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TableGroupsPipe } from './_pipes/table-groups.pipe';
import { NoWebsocketConnectionComponent } from './no-websocket-connection/no-websocket-connection.component';
import { StickyThingDirective } from './_directives/sticky-thing.directive';
import { StatusAvailablePipe } from './_pipes/status-available.pipe';
import { TimeSincePipe } from './_pipes/time-since.pipe';
import { ReduceStringArrayPipe } from './_pipes/reduce-string-array.pipe';
import { SortOrdersByDatePipe } from './_pipes/sort-orders-by-date.pipe';
import { ToggleFullScreenComponent } from './toggle-full-screen/toggle-full-screen.component';
import { MatKeyboardModule } from 'angular-onscreen-material-keyboard';
import { VirtualKeyboardComponent } from './virtual-keyboard/virtual-keyboard.component';
import { OrderDeliveryTypeIconComponent } from './order-delivery-type-icon/order-delivery-type-icon.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';
import { VersionComponent } from './version/version.component';
import { ChargeComponent } from './charge/charge.component';
import { PaymentMethodNamePipe } from './_pipes/payment-method-name.pipe';
import { IsPaymentTypeEnabledPipe } from './_pipes/is-payment-type-enabled.pipe';

import { registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr';
import { HasAdminPermissionPipe } from './_pipes/hasAdminPermission.pipe';
import { ImageSizePipe } from './_pipes/imageSize.pipe';
import { WoltOrderDetailsComponent } from './wolt-order-details/wolt-order-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PropertyItemComponent } from './property-item/property-item.component';
import { WoltOrderStatusNamePipe } from './wolt-order-details/wolt-order-status-name.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SelectPreparationTimeComponent } from './select-preparation-time/select-preparation-time.component';
import { PreserveNewLinePipe } from './_pipes/preserve-new-line.pipe';
import { SelectRejectReasonComponent } from './select-reject-reason/select-reject-reason.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { ProviderIconComponent } from './provider-icon/provider-icon.component';

import { DragToScrollDirective } from './_directives/drag-to-scroll.directive';
import { OrderIconsComponent } from './order-icons/order-icons.component';
import { MrDOrderDetailsComponent } from './mr-dorder-details/mr-dorder-details.component';
import { CodeSigninComponent } from './code-signin/code-signin.component';
import { SplitLettersDirective } from './code-signin/split-letters.directive';
import { OrderProgressBarComponent } from './order-progress-bar/order-progress-bar.component';
import { OrderProgressColorPipe } from './_pipes/order-progress-color.pipe';
import { ProgressBarColor } from './_directives/ProgressBarColor.directive';

registerLocaleData(localeSr);

@NgModule({
  declarations: [
    TranslationComponent,
    NameTranslationEditComponent,
    OrderStatusPipe,
    EnumCheckboxSelectorComponent,
    EnumEnumeratorPipe,
    HasPermissionPipe,
    AlertDialogComponent,
    IsBusyDirective,
    InvoiceDetailsComponent,
    ToBundleNamePipe,
    QRCodeScannerComponent,
    ToAllergenClassPipe,
    ToAllergenNamePipe,
    InViewDirective,
    GetProductByIDPipe,
    ParentProductPipe,
    GroupOrderItemModifiersPipe,
    OrderItemDetailsComponent,
    ProductsInGroupPipe,
    ToGroupIconClassPipe,
    ChildProductsPipe,
    ToProductsPipe,
    SortPipe,
    LoginComponent,
    LogoutComponent,
    RouteLoadingComponent,
    SaveButtonComponent,
    OrderStatusSelectorComponent,
    TableGroupsPipe,
    NoWebsocketConnectionComponent,
    StickyThingDirective,
    StatusAvailablePipe,
    ToProductPipe,
    TimeSincePipe,
    ReduceStringArrayPipe,
    SortOrdersByDatePipe,
    ToggleFullScreenComponent,
    VirtualKeyboardComponent,
    FullNamePipe,
    OrderDetailsComponent,
    GroupBoxComponent,
    ToDeliveryTypeNamePipe,
    OrderDeliveryTypeIconComponent,
    OrderDetailsDialogComponent,
    VersionComponent,
    ChargeComponent,
    PaymentMethodNamePipe,
    GoogleSigninButtonDirectiveDirective,
    IsPaymentTypeEnabledPipe,
    HasAdminPermissionPipe,
    ImageSizePipe,
    EventNamePipe,
    WoltOrderDetailsComponent,
    PropertyItemComponent,
    WoltOrderStatusNamePipe,
    SelectPreparationTimeComponent,
    PreserveNewLinePipe,
    SelectRejectReasonComponent,
    DialogTitleComponent,
    ProviderIconComponent,
    DragToScrollDirective,
    OrderIconsComponent,
    MrDOrderDetailsComponent,
    CodeSigninComponent,
    SplitLettersDirective,
    OrderProgressBarComponent,
    OrderProgressColorPipe,
    ProgressBarColor,
    FilterOrdersByStatusPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ZXingScannerModule,
    MatSnackBarModule,
    HttpClientModule,
    MatProgressBarModule,
    MatIconModule,
    SweetAlert2Module.forChild(),
    MatKeyboardModule,
    DragDropModule,
  ],
  exports: [
    TranslationComponent,
    NameTranslationEditComponent,
    OrderStatusPipe,
    EnumCheckboxSelectorComponent,
    EnumEnumeratorPipe,
    HasPermissionPipe,
    AlertDialogComponent,
    IsBusyDirective,
    ToBundleNamePipe,
    QRCodeScannerComponent,
    ToAllergenClassPipe,
    ToAllergenNamePipe,
    InViewDirective,
    GetProductByIDPipe,
    ParentProductPipe,
    GroupOrderItemModifiersPipe,
    OrderItemDetailsComponent,
    ProductsInGroupPipe,
    ToGroupIconClassPipe,
    ChildProductsPipe,
    ToProductsPipe,
    SortPipe,
    LoginComponent,
    LogoutComponent,
    RouteLoadingComponent,
    SaveButtonComponent,
    OrderStatusSelectorComponent,
    TableGroupsPipe,
    NoWebsocketConnectionComponent,
    StickyThingDirective,
    StatusAvailablePipe,
    ToProductPipe,
    TimeSincePipe,
    ReduceStringArrayPipe,
    SortOrdersByDatePipe,
    ToggleFullScreenComponent,
    FullNamePipe,
    OrderDetailsComponent,
    GroupBoxComponent,
    ToDeliveryTypeNamePipe,
    OrderDeliveryTypeIconComponent,
    OrderDetailsDialogComponent,
    VersionComponent,
    ChargeComponent,
    PaymentMethodNamePipe,
    HasAdminPermissionPipe,
    ImageSizePipe,
    EventNamePipe,
    DialogTitleComponent,
    ProviderIconComponent,
    DragToScrollDirective,
    OrderIconsComponent,
    OrderProgressBarComponent,
    FilterOrdersByStatusPipe
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AccessDeniedInterceptor, multi: true },
    AuthTokenLoadedResolver,
    { provide: LOCALE_ID, useValue: 'sr' }
  ]
})
export class CoreModule {
  constructor(appVersionCheck: AppVersionCheckService) {

  }
}
