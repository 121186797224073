import Swal from 'sweetalert2';
import { SessionService } from './../_services/session.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Order } from 'src/app/core/_interface/order';
import { Component, Input, OnInit } from '@angular/core';
import { ChargeComponent } from '../charge/charge.component';
import { Payment } from '../_interface/payment';
import { TableService } from '../_services/table.service';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: './order-details-dialog.component.html',
  styleUrls: ['./order-details-dialog.component.scss']
})
export class OrderDetailsDialogComponent {
  @Input() order?: Order;
  @Input() groupInTable?: string;
  isBusy = false;

  constructor(private dialgRef: MatDialogRef<OrderDetailsDialogComponent>,
    private dialog: MatDialog,
    private tableService: TableService,
    public session: SessionService) { }

  statusChanged() {
    this.dialgRef.close();
  }

  charge() {
    var ref = this.dialog.open(ChargeComponent, {
      autoFocus: false
    });

    ref.componentInstance.totalAmount = this.order!.total;
    ref.componentInstance.paymentsSelected.subscribe(
      (p) => {
        this.sendPayments(p);
      }
    )
  }


  sendPayments(payments: Payment[]) {
    if (this.isBusy || !this.order) {
      return;
    }

    this.isBusy = true;
    return this.tableService.chargeSingleOrder(this.order?.orderID, payments, this.order?.total).subscribe(
      {
        error: error => {
          Swal.fire({
            title: "Greška",
            text: error,
            icon: "error"
          });
          this.isBusy = false;

          throw 'error';
        }
        , next: val => {
          this.isBusy = false;
          this.dialgRef.close();
        }

      }
    );
  }

}
