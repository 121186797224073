import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-click-here-animation',
  templateUrl: './click-here-animation.component.html',
  styleUrls: ['./click-here-animation.component.scss']
})
export class ClickHereAnimationComponent implements OnInit {

  @ViewChild('container') container!: ElementRef<HTMLDivElement>;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
  }

}
