import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil, Subscription } from 'rxjs';
import { interval } from 'rxjs/internal/observable/interval';
import { Order } from '../_interface/order';

@Component({
  selector: 'app-order-progress-bar',
  templateUrl: './order-progress-bar.component.html',
  styleUrls: ['./order-progress-bar.component.scss']
})
export class OrderProgressBarComponent implements OnInit, OnDestroy {
  _oldDueDate?: string;

  destroyed = new Subject<void>();
  progressValue = 0;

  progressUpdater?: Subscription;

  @Input() order?: Order;


  constructor() { }
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.order?.dueDate != this._oldDueDate) {
      this._oldDueDate = this.order?.dueDate;
      this.updateTimer();
    }
  }

  updateTimer() {
    if (!this.order?.dueDate) {
      return;
    }

    var dueDate = new Date(this.order.dueDate);
    var startDate = new Date(this.order.modified!);

    var totalTime = dueDate.getTime() - startDate.getTime();

    if (this.progressUpdater){
      this.progressUpdater.unsubscribe();
    }

    this.progressUpdater = interval(500).pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      sec => {
        var elapsed = (new Date()).getTime() - startDate.getTime();
        this.progressValue = elapsed * 100 / totalTime;

        if (this.progressValue > 100) {
          this.progressUpdater?.unsubscribe();
        }
      }
    )
    this.progressValue = 0;
  }
}
