<div
  *ngIf="item"
  class="container"
  [class.canceled]="item.isCanceled || isCanceled"
>
  <span class="quantity" *ngIf="showQuantity">{{ item.quantity }}x</span>
  <div class="container">
    <div class="details">
      <span class="name">
        <ng-container
          *ngIf="
            item.productID
              | getProductByID
              | async
              | parentProduct
              | async as parent
          "
        >
          {{ parent?.name }} -</ng-container
        >
        {{ (item.productID | getProductByID | async)?.name || item.name }}
      </span>
      <div
        class="modifierItem"
        *ngFor="
          let group of (item.modifiers | groupOrderItemModifiers | async) || []
        "
      >
        + <span *ngIf="group.modifier.name">{{ group.modifier.name }}:</span>
        <span class="name modifierProduct" *ngFor="let item of group.items">
          <span *ngIf="item.quantity > 1" class="quantity"
            >{{ item.quantity }}x</span
          >
          {{ (item.productID | getProductByID | async)?.name }}</span
        >
      </div>
      <div *ngIf="item.note">{{ item.note }}</div>
    </div>
    <div class="prices">
      <div class="price" *ngIf="!hidePrice">
        <span>RSD</span>{{ item.total | number: "1.2" }}
      </div>
    </div>
  </div>
</div>
