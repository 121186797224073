import { OrderItem } from 'src/app/core/_interface/order';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-item-details',
  templateUrl: './order-item-details.component.html',
  styleUrls: ['./order-item-details.component.scss']
})
export class OrderItemDetailsComponent {
  @Input() item?: OrderItem;
  @Input() showQuantity = true;
  @Input() isCanceled = false;
  @Input() hidePrice = false;

  constructor() { }

}
