<ng-container [formGroup]="formGroup" ]>
  <ul formArrayName="items" class="enumList">
    <li *ngFor="let item of itemCheckControls; let i = index" class="enumItem">
      <mat-checkbox
        (change)="selectionChanged()"
        [formControl]="item"
      >
        <ng-container
          [ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultItemTemplate"
          [ngTemplateOutletContext]="{ item: this.enum[this.keys[i]] }"
        ></ng-container>
      </mat-checkbox>
    </li>
  </ul>
</ng-container>
<ng-template #defaultItemTemplate let-item="item">
  {{ itemName(item) }}
</ng-template>
