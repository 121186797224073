<div class="error" *ngIf="invalidSession">
  Vaša sesija je istekla. Prijavite se ponovo
</div>
<ng-container
  *ngIf="(myAuthService.currentUser | async) === null; else loggedIn"
>
  <ng-container *ngIf="showLogInForm">
    <form
      class="loginForm"
      *ngIf="!isBusy; else loading"
      [formGroup]="loginForm"
    >
      <mat-form-field>
        <mat-label>eMail</mat-label>
        <input
          matInput
          autocomplete="username"
          required
          formControlName="username"
          #eMail
        />
        <app-virtual-keyboard
          matSuffix
          [control]="loginForm.get('username')"
          [inputElement]="eMail"
        ></app-virtual-keyboard>
        <mat-error
          *ngIf="
            loginForm.get('username')!.touched &&
            loginForm.get('username')!.hasError('required')
          "
          >podatak je obavezan
        </mat-error>
        <mat-error
          *ngIf="
            loginForm.get('username')!.touched &&
            loginForm.get('username')!.hasError('email')
          "
          >format nije ispravan
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Šifra</mat-label>
        <input
          matInput
          autocomplete="current-password"
          required
          formControlName="password"
          type="password"
          #password
        />
        <app-virtual-keyboard
          matSuffix
          [control]="loginForm.get('password')"
          [inputElement]="password"
        ></app-virtual-keyboard>

        <mat-error
          *ngIf="
            loginForm.get('password')!.touched &&
            loginForm.get('password')!.hasError('required')
          "
          >podatak je obavezan
        </mat-error>
      </mat-form-field>

      <button
        class="submit dropShadowPrimary"
        mat-raised-button
        color="primary"
        (click)="login()"
      >
        ULOGUJ SE
      </button>
    </form>
  </ng-container>
  <div class="socialLogin">
    <div>
      <div class="line"></div>
      <span>Uloguj se preko</span>
    </div>

    <div class="buttons">
      <button class="socialLoginButton custom" (click)="showLogInForm = true">
        <img src="./assets/icons/icon-96x96.png" alt="" />
      </button>

      <button
        class="socialLoginButton custom"
        (click)="signInWithCode()"
        *ngIf="showQRLogin"
      >
        <img src="./assets/qr-icon.png" alt="" />
      </button>

      <div appGoogleSigninButtonDirective class="socialLoginButton"></div>

      <button class="socialLoginButton custom" (click)="signInWithFB()">
        <img src="./assets/FB-icon.png" alt="" />
      </button>
    </div>
  </div>

  <app-code-signin class="codeSignin" [successLoginRedirect]="successLoginRedirect" *ngIf="enableCodeSignin"></app-code-signin>
</ng-container>

<ng-template #loading>
  <div class="loading">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>

<ng-template #loggedIn><button mat-stroked-button (click)="logout()">LOGOUT</button></ng-template>
