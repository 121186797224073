<div class="description">

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Detalji o proizvodu</div>
      </div>
      <div class="sectionDetails">Ovaj prozor prikazuje detalje o proizvodu, uključujući opis, alergene i dostupne modifikatore.</div>
    </div>

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Dodavanje u korpu</div>
      </div>
      <div class="sectionDetails">Odaberite količinu, modifikatore, unesite napomenu i dodajte artikal u korpu.</div>
    </div>
  
  </div>