import { AfterViewInit } from '@angular/core';
import { Translation } from './tranlsation';

export enum Allergens {
  Celery = 1,
  Egg = 2,
  Peanuts = 3,
  Lupine = 4,
  Sesame = 5,
  Mustard = 6,
  Crustacean = 7,
  Sulfur = 8,
  Soya = 9,
  Milk = 10,
  Fish = 11,
  Mollusca = 12,
  Nut = 13,
  Wheat = 14
}

export enum ProductType {
  Product = 0,
  ProductGroup = 1,
  Modifier = 2,
}

export interface Suggestion {
  suggestionID: number;
  name: string;
  productIDs: number[];
  available: boolean;
  companyID: number;
}

export interface IName {
  name: string;
}
export interface ISortable {
  sortOrder: number;
}
export interface ProductBase extends IName {
  description: string;
  image: string;
  translations: Translation[]

}

export interface DefaultProduct extends ProductBase {
  productID: number;
  tags: string[];
  allergens: Allergens[];
}

export interface Product extends ProductBase, ISortable {
  productID: number;
  price: number;
  isFeatured: boolean;
  allergens: Allergens[];
  referenceID: string;
  type: ProductType;
  modifierIDs: number[];
  deleted: boolean;
  parentProductID?: number;
  suggestionIDs: number[];
  available: boolean;
  validFrom?: string;
  validUntil?: string;
  autoShowModifier: boolean;
  preparationTime: number | null;
}

export enum GroupCategory {
  Default = 0,
  Drink,
  Food,
  Berbecue,
  Sandwich,
  Alcohol,
  NonAlcohol,
  Cigarette,
  KidsMenu,
  Desert,
  Brekfast,
  Coctail,
  Startup,
  Sallate,
  Pasta,
  Beer,
  Fish,
  Cheese,
  Soup,
  HotDrinks,
  Vine
}
export interface Group extends ISortable {
  name: string;
  category: GroupCategory;
  groupID: number;
  modifierIDs: number[];
  translations: Translation[]
  suggestionIDs: number[];
  productIDs: number[];
  validFrom?: string;
  validUntil?: string;
  available: boolean;
}


export const createProduct = () => {
  return {
    name: '',
    sortOrder: 0,
    translations: [],
    allergens: [],
    available: true,
    description: '',
    image: '',
    productID: 0,
    deleted: false,
    price: 0,
    isFeatured: false,
    referenceID: '',
    type: ProductType.Product,
    modifierIDs: [],
    suggestionIDs: [],
    autoShowModifier: false,
    preparationTime: null
  } as Product;
}
