<swal #mySwal title="Potvrda" [showCancelButton]="true"></swal>

<div *ngIf="order">
  <div
    class="warning"
    *ngIf="
      order.status === OrderStatus.NewPreorder ||
      order.status === OrderStatus.Pending
    "
  >
    Porudžbina je odložena!
    <div>
      Željeno vreme isporuke
      {{ order.dueDate | date: "dd.MM.yyyy. u HH:mm" }}
    </div>
  </div>

  <ng-container
    *ngIf="order.status === OrderStatus.NewPreorder; else selectStatus"
  >
    <div class="statusGroup">
      <button
        mat-stroked-button
        color="primary"
        (click)="confirmStatusChange(OrderStatus.Pending)"
        class="orderStatus"
      >
        {{ OrderStatus.Pending | orderStatus: order.status }}
      </button>

      <button
        mat-stroked-button
        color="primary"
        (click)="confirmStatusChange(OrderStatus.Rejected)"
        class="orderStatus"
      >
        {{ OrderStatus.Rejected | orderStatus: order.status }}
      </button>
    </div>
  </ng-container>
  <ng-template #selectStatus>
    <div class="statusGroup">
      <ng-container *ngFor="let status of OrderStatus | enumEnumerator">
        <button
          mat-stroked-button
          color="primary"
          [class.mat-flat-button]="status === order.status"
          (click)="confirmStatusChange(status)"
          class="orderStatus"
          [disabled]="isDisabled(status)"
          *ngIf="status | statusAvailable | async"
        >
          {{ status | orderStatus: order.status }}
        </button>
      </ng-container>
    </div>
  </ng-template>
</div>
