import { Pipe, PipeTransform } from '@angular/core';
import { EventType } from '../_interface/event';

@Pipe({
  name: 'eventName'
})
export class EventNamePipe implements PipeTransform {

  transform(type: EventType | string) {
    switch (type) {
      case (EventType.ChargeExecuted):
        return "Naplaćeno";
      case (EventType.MenuViewed):
        return "Otvoren meni";
      case (EventType.OrderCreatedWaiter):
        return "Poručeno - konobar";
      case (EventType.OrderCreatedUser):
        return "Poručeno - korisnik";
      case (EventType.PaymentCacheRequested):
        return "Zatraženo plaćanje - gotovina";
      case (EventType.PaymentCardRequested):
        return "Zatraženo plaćanje - kartica";
      case (EventType.ProductViewed):
        return "Artikal pogledan";
      case (EventType.QRCodeScaned):
        return "QR kod skeniran"
      case (EventType.TableTotalRequested):
        return "Traženo zaduženje stola";
      case (EventType.WaiterCalled):
        return "Pozvan konobar";
      case (EventType.NoResponseFromPOS):
        return "POS nije dostupan";
      default:
        return "";
    }
  }

}
