import { MrDOrderDetailsComponent } from './../mr-dorder-details/mr-dorder-details.component';
import { WoltOrderDetailsComponent } from './../wolt-order-details/wolt-order-details.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DeliveryType, Order, OrderStatus } from 'src/app/core/_interface/order';
import { OrderService } from 'src/app/core/_services/order.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent {
  DeliveryType = DeliveryType;
  OrderStatus = OrderStatus;

  @Input() order?: Order;
  @Input() groupInTable?: string;

  @Output() statusChanged = new EventEmitter<OrderStatus>();
  constructor(private ordersService: OrderService,
    private dialog: MatDialog,
    private toast: ToastrService) { }



  changeOrderStatus(e: { orderID: number, status: OrderStatus, preparationTime?: number, rejectReason?: string }) {
    this.setOrderStatus(e.orderID, e.status, e.preparationTime, e.rejectReason);
  }


  setOrderStatus(orderID: number, status: OrderStatus, preparationTime?: number, rejectReason?: string) {

    this.ordersService.setStatus(orderID, status, this.groupInTable || this.order!.group, preparationTime, rejectReason).subscribe({
      next: success => {
        this.statusChanged.emit(status);
        this.order!.status = status;
        this.order!.rejectReason = rejectReason || '';

        this.toast.success("Status promenjen");
      },
      error: (e: string) => {
        Swal.fire({
          title: "Greška",
          text: e,
          icon: "error"
        });
      }
    }

    )
  }

  showProviderDetails() {
    switch (this.order?.provider) {
      case ("wolt"):
        var ref = this.dialog.open(WoltOrderDetailsComponent);
        ref.componentInstance.orderID = this.order.orderID;
        break;
      case ("mrd"):
        var refMrd = this.dialog.open(MrDOrderDetailsComponent);
        refMrd.componentInstance.orderID = this.order.orderID;
        break;
    }
  }

}
