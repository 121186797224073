import { Component, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-orders-description',
  templateUrl: './orders-description.component.html',
  styleUrls: ['./orders-description.component.scss']
})
export class OrdersDescriptionComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

}
