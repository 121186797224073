import { GenericItemApiResponse, GenericListApiResponse } from 'src/app/core/_interface/apiResponse';
import { CancelOrderItem, CancelOrderItemResponse, CreatedOrder, Order, OrderItem, SearchOrderOptions } from 'src/app/core/_interface/order';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../_interface/apiResponse';
import { DeliveryType, OrderDeliveryDetail, OrderStatus } from '../_interface/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(public http: HttpClient) { }

  setStatus(orderID: number,
    status: OrderStatus,
    group?: string,
    preparationTime?: number,
    rejectReason?: string): Observable<boolean> {

    const url = environment.apiBase + `order/SetStatus`;
    var data = {
      orderID: orderID,
      status: status,
      group: group,
      preparationTime: preparationTime,
      rejectReason
    };

    return this.http.post<GenericItemApiResponse<string>>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return response.success
          }

          throw response.item;
        })
      );
  }

  save(companyID: number, order?: Order | null) {
    const url = environment.apiBase + `order/create`;
    var data = {
      companyID,
      order
    }

    return this.http.post<GenericItemApiResponse<CreatedOrder>>(url, data).pipe(
      map(result => {
        if (result.success) {
          return result.item;
        }

        throw result;
      })
    );
  }

  cancelItems(tableID: number, items?: OrderItem[]) {
    const url = environment.apiBase + `order/CancelOrderItems`;
    var data = {
      tableID: tableID,
      orderItems: items?.map(i => {
        return {
          orderItemID: i.orderItemID,
          orderItemReferenceID: i.orderItemReferenceID
        }
      }) || [],
    }

    return this.http.post<ApiResponse>(url, data).pipe(
      map(result => {
        if (result.success) {
          return null;
        }

        throw result.errorDescription;
      })
    );
  }

  search(searchParam: SearchOrderOptions) {
    const url = environment.apiBase + `order/get`;

    return this.http.post<GenericListApiResponse<Order>>(url, searchParam)
      .pipe(
        map(response => {
          if (response.success) {
            var list: Order[] = [];
            response.items.forEach(i => {
              var no = new Order(i);

              list.push(no);
            });

            return list;
          }
          return null;
        }),
        tap(items => {
          items?.forEach(o => o.created = new Date(o.created));
        })
      );
  }

  getActiveInstantOrders(companyID: number) {

    var searchParam: SearchOrderOptions = {
      companyID: companyID,
      status: [OrderStatus.Accepted, OrderStatus.New, OrderStatus.InProduction, OrderStatus.Invalid, OrderStatus.NewPreorder, OrderStatus.Pending],
      deliveryType: [DeliveryType.Pickup, DeliveryType.Delivery]
    };

    return this.search(searchParam);
  }
}
