import { UserBase } from '../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value?: UserBase | null) {
    if (!value) return null;

    return (value.firstName + " " + value.lastName).trim();
  }

}
