import { OrderItemModifier } from '../_interface/order';
import { Observable, forkJoin, of, combineLatest } from 'rxjs';
import { Pipe, PipeTransform } from "@angular/core";
import { map } from 'rxjs/operators';
import { SessionService } from '../_services/session.service';
import { IModifier } from 'src/app/core/_interface/modifier';

@Pipe({ name: 'groupOrderItemModifiers' })
export class GroupOrderItemModifiersPipe implements PipeTransform {
  constructor(private session: SessionService) {

  }
  transform(modifiers: OrderItemModifier[]): Observable<{ modifier: IModifier, items: OrderItemModifier[] }[]> | null {
    if (modifiers.length > 0) {

      return combineLatest([this.session.modifiers, this.session.products]).pipe(
        map(
          () => {

            var x = modifiers.reduce((p, m) => {
              if (!p[m.modifierID.toString()]) {
                var modifier = this.session.getModifier(m.modifierID);
                if (!modifier) {
                  modifier = {
                    maxQuantity: 0,
                    modifierID: 0,
                    name: '',
                    productIDs: [],
                    translations: [],
                    available: true
                  };

                }

                p[m.modifierID.toString()] = { modifier: modifier, items: [] };
              }

              p[m.modifierID.toString()].items.push(m);

              return p;

            }, {} as { [key: string]: { modifier: IModifier, items: OrderItemModifier[] } })


            return Object.values(x);
          }
        )
      );
    }
    return null;
  }
}
