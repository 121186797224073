import { Component, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tables-description',
  templateUrl: './tables-description.component.html',
  styleUrls: ['./tables-description.component.scss']
})
export class TablesDescriptionComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }

}
