import { Order, OrderStatus } from 'src/app/core/_interface/order';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOrdersByStatus'
})
export class FilterOrdersByStatusPipe implements PipeTransform {

  transform(orders: Order[], status: OrderStatus[]) {
    return orders.filter(o => status.some(s => s == o.status));
  }

}
