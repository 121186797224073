import { Component, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-main-description',
  templateUrl: './main-description.component.html',
  styleUrls: ['./main-description.component.scss']
})
export class MainDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
