
import { deepEqual } from '../_helpers/equaleCompare';
import { Waiter } from './user';

export enum OrderStatus {
  New = 1,
  Accepted = 3,
  InProduction = 4,
  Ready = 5,
  Dispached = 6,
  Delivered = 7,
  Rejected = 8,
  Canceled = 9,
  Completed = 10,
  Invalid = 11,
  NewPreorder = 12,
  Pending = 13,
}

export enum DeliveryType {
  InHouse = "InHouse",
  Pickup = "Pickup",
  Delivery = "Delivery"
}

export interface UserBase {
  userID: number;
  firstName: string;
  lastName: string;
  eMail: string;
}

export class Order {
  companyID = 0;
  orderID = 0;
  created!: Date;
  deliveryType!: DeliveryType;
  status!: OrderStatus;
  tableID?: number;
  items: OrderItem[] = [];
  dueDate?: string;
  modified?: string | Date;
  deliveryDetail?: OrderDeliveryDetail;
  createdBy?: UserBase;
  waiter?: Waiter;
  note: string = '';
  orderNo: string = '';
  group: string = '';
  rejectReason = '';
  provider: 'wolt' | 'mrd' | '' = '';

  get total(): number {
    return this.items.filter(i => !i.isCanceled).reduce((s, i) => {
      return s + i.total;
    }, 0);
  }

  constructor(json: any) {
    Object.assign(this, json);

    this.created = new Date(this.created);

    this.items.forEach((item, index) => {
      this.items[index] = OrderItem.fromJSON(item);
    });

    this.deliveryType = <any>DeliveryType[this.deliveryType];
  }


  replaceOrderItem(old: OrderItem, newItem: OrderItem) {
    var index = this.items.indexOf(old);
    if (index != -1) {
      this.items[index] = newItem;
    }
  }

  addItem(item: OrderItem): void {
    const hash = JSON.stringify(item);

    let found = false;
    for (const i of this.items) {
      const tmp1 = {} as OrderItem;
      Object.assign(tmp1, i);
      tmp1.quantity = 0;

      const tmp2 = {} as OrderItem;
      Object.assign(tmp2, item);
      tmp2.quantity = 0;

      if (deepEqual(tmp1, tmp2)) {
        i.quantity += item.quantity;
        found = true;
        break;
      }
    }

    if (!found) {
      this.items.push(item);
    }

    this.items = [... this.items];
  }
}

export class OrderItemModifier {
  modifierID = 0;
  productID = 0;
  quantity = 0;
  price = 0;
  get total() {
    return this.quantity * this.price;
  }

  static fromJSON(json: any): OrderItemModifier {
    const i = new OrderItemModifier();
    Object.assign(i, json);
    return i;
  }
}

export class OrderItem {
  orderItemID: number = 0;
  modifiers: OrderItemModifier[] = [];
  quantity: number = 0;
  productID: number = 0;
  referenceID: string = '';
  name: string = '';
  note: string = '';
  price: number = 0;
  isCanceled = false;
  orderItemReferenceID = '';

  get total() {
    return this.quantity * (this.price + this.modifierTotal());
  }

  private modifierTotal(): number {
    return this.modifiers?.reduce((s, i) => {
      return s + i.total;
    }, 0) || 0;
  }

  static fromJSON(json: any): OrderItem {
    const oi = new OrderItem();
    Object.assign(oi, json);

    oi.modifiers = oi.modifiers || [];

    oi.modifiers?.forEach((m, i) => {
      oi.modifiers[i] = OrderItemModifier.fromJSON(m);
    })
    return oi;
  }
}

export interface OrderDeliveryDetail {
  name: string;
  phoneNo: string;
  address: string;
  note: string;
}
export interface CancelOrderItem {
  tableID: number;
  orderItemID: string;
}
export interface CreatedOrder {
  orderID: number;
  orderNo: string;
  status: OrderStatus;
  date: string;
  rejectReason: string;
  journal: string;
  verificationURL: string;
}
export interface SearchOrderOptions {
  companyID: number;
  createdFrom?: string | null | Date;
  createdTo?: string | null | Date;
  modifiedFrom?: string | null;
  modifiedTo?: string | null;
  fromOrderID?: number | null;
  status?: OrderStatus[];
  deliveryType?: DeliveryType[] | null;
}

export interface CancelOrderItemResponse {
  success: boolean;
  error: string;
}
