import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Directive, ElementRef } from '@angular/core';
import { take } from 'rxjs';
declare var google: any;

@Directive({
  selector: '[appGoogleSigninButtonDirective]'
})
export class GoogleSigninButtonDirectiveDirective {

  constructor(private el: ElementRef, private socialAuthService: SocialAuthService) {
  }

  ngOnInit() {

    this.socialAuthService.initState.pipe(take(1)).subscribe(() => {
      google.accounts.id.renderButton(this.el.nativeElement, {
        type: 'icon',
        size: 'large',
      });
    });
  }

}
