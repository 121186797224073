<div (click)="getToken()">
<qr-code
  *ngIf="code; else noCode"
  [value]="code"
  size="200"
  errorCorrectionLevel="M"
></qr-code>

<ng-template #noCode>Generiši kod</ng-template>
</div>
