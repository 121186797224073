<div class="description">
  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Poveži se na WiFi</div>
    </div>
    <div class="sectionDetails">
      Dostupni su detalji za povezivanje na bežičnu mrežu restorana, kao i
      mogućnost da gost pokaže QR kod svom društvu kako bi se i oni povezali na
      bežičnu mrežu.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Traži račun gotovina/kartica</div>
    </div>
    <div class="sectionDetails">
      Gost ima mogućnost da zatraži račun iz eMenija, a konobar će odmah dobiti
      zahtev.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Zaduženje stola</div>
    </div>
    <div class="sectionDetails">
      Gost ima mogućnost da u svakom trenutku proveri trenutno zaduženje stola.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Pozovi konobara</div>
    </div>
    <div class="sectionDetails">
      Gost može jednim klikom preko eMenija da signalizira konobaru da dođe za
      njegov sto.
    </div>
  </div>
</div>
