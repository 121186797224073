
import { MainComponent } from './main/main.component';
import { UserAppComponent } from './user-app/main/user-app.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaiterComponent } from './waiter/main/waiter.component';
import { LoginComponent } from './login/login.component';
import { LoggedinGuard } from './loggedin.guard';

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    canActivate: [LoggedinGuard]
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "waiter",
    component: WaiterComponent,
    canActivate: [LoggedinGuard]
  },
  {
    path: "userapp",
    component: UserAppComponent,
    canActivate: [LoggedinGuard]

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
