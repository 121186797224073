import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap, map } from 'rxjs';
import { CompanySettings, ICompany } from '../_interface/company';
import { IModifier } from '../_interface/modifier';
import { Group, Product } from '../_interface/product';
import { CompanyService } from './company.service';
import { GroupService } from './group.service';
import { ModifierService } from './modifier.service';
import { ProductService } from './product.service';
import { WebsocketData, WSService } from './ws.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  wsConnectionInitialized = new BehaviorSubject(false);

  selectedCompany = new BehaviorSubject<ICompany | null>(null);
  _products: BehaviorSubject<Product[]> | null = null;
  _modifiers: BehaviorSubject<IModifier[]> | null = null;
  private _companySettings: BehaviorSubject<CompanySettings | null> | null = null;


  private _groups: BehaviorSubject<Group[]> | null = null;

  constructor(
    private modifierService: ModifierService,
    private groupService: GroupService,
    private productService: ProductService,
    private companyService: CompanyService,
    private ws: WSService
  ) {

    productService.productSaved.subscribe({ next: p => this.onProductSaved(p) })
    productService.productsDeleted.subscribe({ next: p => this.onProductsDeleted(p) })

    groupService.groupSaved.subscribe({ next: g => this.onGroupSaved(g) })

    modifierService.modifierSaved.subscribe({ next: s => this.onModifierSaved(s) })
    modifierService.modifierDeleted.subscribe({ next: s => this.onModifierDeleted(s) })

    companyService.companySettingsSaved.subscribe({ next: g => this.onCompanySettingsSaved(g) })

    ws.authenticated.subscribe(
      authenticated => {
        if (authenticated) {
          this.sendWSCompanyID(this.selectedCompany.value);
        }
      }
    );

    ws.observe('companySettingsChanged').subscribe(
      {
        next: (msg) => {
          if (this._companySettings) {
            this._companySettings?.next(msg.data.settings);
          }
        }
      }
    );

    this.selectedCompany.subscribe(
      company => {
        if (company) {
          this.load();
          this.sendWSCompanyID(company);
        }
      }
    )
  }

  load() {
    if (this._groups) this.loadGroups();
    if (this._products) this.loadProducts();
    if (this._modifiers) this.loadModifiers();
    if (this._companySettings) this.loadCompanySettings();
  }

  onProductsDeleted(ids: number[]): void {
    var newList = this._products?.value.filter(p => !ids.some(pid => pid == p.productID));
    if (newList) {
      this._products?.next(newList);
    }
  }

  sendWSCompanyID(company: ICompany | null) {
    if (!company) {
      return;
    }

    const data: WebsocketData = {
      type: "setCompany",
      data: company?.companyID
    };
    this.ws.send(data);

    this.wsConnectionInitialized.next(true);
  }

  get companyID(): number {
    return this.selectedCompany.value?.companyID || 0;
  }

  onGroupSaved(s: any): void {
    const list = this.groups.value;
    this.addOrUpdate(list, s, "groupID");
    this.groups.next(list)
  }

  get groups(): BehaviorSubject<Group[]> {
    if (!this._groups) {
      this._groups = new BehaviorSubject<Group[]>([]);
      this.loadGroups();
    }

    return this._groups;
  }

  private loadGroups() {
    if (!this.companyID) {
      return;
    }

    this.groupService.get(this.companyID).subscribe({
      next: (data) => this._groups?.next(data!)
    });
  }


  onCompanySettingsSaved(s: CompanySettings): void {
    if (this._companySettings) {
      this._companySettings.next(s);
    }
  }

  get companySettings() {
    if (!this._companySettings) {
      this._companySettings = new BehaviorSubject<CompanySettings | null>(null);
      this.loadCompanySettings();
    }

    return this._companySettings;
  }


  private loadCompanySettings() {
    if (!this.companyID) {
      return;
    }

    this.companyService.getSettings(this.companyID).subscribe({
      next: (data) => this._companySettings?.next(data)
    });
  }

  get products(): BehaviorSubject<Product[]> {
    if (!this._products) {
      this._products = new BehaviorSubject<Product[]>([]);
      this.loadProducts();
    }

    return this._products;
  }

  getSingleProduct(productID: number) {
    return this.productService.getSingleProduct(this.companyID, productID).pipe(
      tap(
        (data) => {
          if (data) {
            if (this._products?.value.some(p => p.productID !== data.productID)) {
              this._products?.next([...this._products.value, data as Product]);
            }
          }
        }
      )
    );
  }

  private loadProducts() {
    if (!this.companyID) {
      return;
    }
    this.productService.get(this.companyID).subscribe({
      next: (data) => this._products?.next(data as Product[])
    });
  }

  moveProductToGroup(p: Product[], newGroupID: number, keepGroups: boolean): Observable<boolean> {

    var ids = p.map(pr => pr.productID);
    this.products.next([...this.products.value]);

    var result = new Subject<boolean>();

    this.productService.moveProductToGroup(this.companyID, ids, newGroupID, keepGroups).subscribe(
      success => {
        result.next(success);
        result.complete();
        if (success) {
          this.loadGroups();
        }
      }
    );

    return result;

  }

  onProductSaved(p: Product): void {
    const list = this.products.value;
    this.addOrUpdate(list, p, "productID");
    this.products.next(list)
  }

  onProductsModified() {
    this.products.next([... this.products.value]);

  }


  get modifiers(): BehaviorSubject<IModifier[]> {
    if (!this._modifiers) {
      this._modifiers = new BehaviorSubject<IModifier[]>([]);
      this.loadModifiers();
    }

    return this._modifiers;
  }

  private loadModifiers() {
    if (!this.companyID) {
      return;
    }

    this.modifierService.get(this.companyID).subscribe({
      next: (data) => this._modifiers?.next(data as IModifier[])
    });
  }

  onModifierSaved(s: IModifier): void {
    const list = this.modifiers.value;
    this.addOrUpdate(list, s, "modifierID");
    this.modifiers.next(list)
  }

  onModifierDeleted(s: IModifier): void {
    const list = this.modifiers.value;
    this.remove(list, s, "modifierID");
    this.modifiers.next(list)
  }

  addOrUpdate(list: any[], item: any, compareKey: string) {
    const existing = list.find(i => i[compareKey] == item[compareKey]);
    if (existing) {
      list.splice(list.indexOf(existing), 1, item);
    } else {
      list.push(item);
    }
  }

  remove(list: any[], item: any, compareKey: string) {
    const existing = list.find(i => i[compareKey] == item[compareKey]);
    if (existing) {
      list.splice(list.indexOf(existing), 1);
    }
  }


  getModifier(modifierID: number): IModifier | undefined {
    return this.modifiers?.value?.find(m => m.modifierID == modifierID);
  }

  getProduct(productID: number): Product | undefined {
    return this.products?.value?.find(m => m.productID == productID);
  }
  clear() {
    this._products = null;
    this._modifiers = null;
    this._groups = null;
    this._companySettings = null;

  }
}
