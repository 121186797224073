<div *ngIf="order">
  <div class="invalidOrder error" *ngIf="order.status === OrderStatus.Invalid">
    Neispravna porudžbina
  </div>
  <div class="summary">
    <div class="provider" *ngIf="order.provider">
      Provajder: <app-provider-icon [provider]="order.provider"></app-provider-icon>
      <mat-icon (click)="showProviderDetails()">info</mat-icon>
    </div>
    <div class="ids">
      <div>
        ID: <span class="primaryColor">#{{ order.orderNo }}</span> ({{
          order.orderID
        }})
      </div>
      <div>
        {{ order.created | date: "dd.MM.yyyy HH:mm:ss" }}
      </div>
    </div>
    <div class="users">
      <div>
        Kreirao: <span class="name">{{ order.createdBy | fullName }}</span>
      </div>
      <div>
        Prihvatio: <span class="name">{{ order.waiter?.name }}</span>
      </div>
    </div>
    <div *ngIf="order.rejectReason">
      Razlog odbijanja: <span class="name">{{ order.rejectReason }}</span>
    </div>
  </div>
  <div class="details">
    <div
      class="warning orderNote"
      *ngIf="order.note"
      [innerHTML]="order.note | preserveNewLine"
    ></div>

    <app-group-box title="Poručeni proizvodi">
      <ul class="productList">
        <li *ngFor="let item of order.items">
          <app-order-item-details [item]="item"></app-order-item-details>
        </li>
      </ul>

      <div class="total">
        Ukupan iznos:
        <span class="price"
          ><span>RSD</span> {{ order.total | number: "1.2" }}
        </span>
      </div>
    </app-group-box>

    <app-group-box
      [title]="'Isporuka: ' + (order.deliveryType | toDeliveryTypeName)"
      class="deliveryDetails"
      *ngIf="order.deliveryType !== DeliveryType.InHouse"
    >
      <div>Naziv: {{ order.deliveryDetail?.name }}</div>
      <div>Telefon: {{ order.deliveryDetail?.phoneNo }}</div>
      <div *ngIf="order.deliveryDetail?.address">
        Adresa: {{ order.deliveryDetail?.address }}
      </div>
      <div *ngIf="order.deliveryDetail?.note">
        Napomena: {{ order.deliveryDetail?.note }}
      </div>
    </app-group-box>
  </div>
  <app-group-box title="Status" class="status">
    <app-order-status-selector
      [order]="order"
      (statusChanged)="changeOrderStatus($event)"
    ></app-order-status-selector>
  </app-group-box>
</div>
