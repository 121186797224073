import { Pipe, PipeTransform } from "@angular/core";
import { ISortable } from "../_interface/product";

@Pipe({ name: "sort" })
export class SortPipe implements PipeTransform {
  transform<T extends ISortable>(products: T[] | null): T[] | null {
    if (products) {
      const sorted = products.sort((a, b) => {
        return a.sortOrder - b.sortOrder
      });

      return sorted
    }
    return null;
  }
}
