<div class="description">
  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Promovišite događaj/jelo/...</div>
    </div>
    <div class="sectionDetails">
      Informišite svoje goste o novostima u vašem objektu.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Pregled menija</div>
    </div>
    <div class="sectionDetails">
      Omogućite gostima da pogledaju vašu ponudu sa svojih mobilnih uređaja.
      Meni može biti dostupan svima ili samo određenoj grupi korisnika.
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Poručivanje</div>
    </div>
    <div class="sectionDetails">
      Gosti mogu samostalno da naprave porudžbinu koja stiže direktno u eBar!
    </div>
  </div>

  <div class="section">
    <div class="title">
      <div class="icon"></div>
      <div class="titleText">Dodatne mogućnosti</div>
    </div>
    <div class="sectionDetails">
      Klikom na eMeni dugme istražite dodatne mogućnosti.
    </div>
  </div>
</div>
