import { map } from 'rxjs/operators';
import { PaymentType } from './../_interface/payment';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../_services/session.service';

@Pipe({
  name: 'isPaymentTypeEnabled'
})
export class IsPaymentTypeEnabledPipe implements PipeTransform {

  constructor(private session: SessionService) {


  }
  transform(value: PaymentType) {
    return this.session.companySettings.pipe(
      map(val => {
        if (!val) {
          return false;
        }
        return val.allowedPaymentTypes.indexOf(value) > -1
      })
    )
  }

}
