import { OrderStatus } from './../_interface/order';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {

  transform(status: OrderStatus, currentStatus?: OrderStatus): string {
    switch (status) {
      case OrderStatus.New: return currentStatus && status !== currentStatus ? "nova" : 'nova';
      //      case OrderStatus.Received: return "primljena"
      case OrderStatus.Accepted: return currentStatus && status !== currentStatus ? 'prihvati' : "prihvaćena";
      case OrderStatus.InProduction: return "u pripremi";
      case OrderStatus.Ready: return "pripremljena"
      case OrderStatus.Dispached: return "poslata"
      case OrderStatus.Delivered: return "isporučena"
      case OrderStatus.Rejected: return currentStatus && status !== currentStatus ? 'odbij' : "odbijena";
      case OrderStatus.Canceled: return currentStatus && status !== currentStatus ? 'otkaži' : "otkazana";
      case OrderStatus.Completed: return currentStatus && status !== currentStatus ? 'završi' : "završena";
      case OrderStatus.Invalid: return "neispravna";
      case OrderStatus.NewPreorder: return "nova odložena";
      case OrderStatus.Pending: return currentStatus && status !== currentStatus ? "potvrdi" : "potvrđena";

    }
  }
}
