
import { Component, Input, OnInit } from '@angular/core';
import { WoltOrder } from '../_interface/wolt';
import { WoltService } from '../_services/wolt.service';

@Component({
  selector: 'app-wolt-order-details',
  templateUrl: './wolt-order-details.component.html',
  styleUrls: ['./wolt-order-details.component.scss']
})
export class WoltOrderDetailsComponent implements OnInit {
  order?: WoltOrder

  @Input() orderID?: number;

  constructor(
    private woltService: WoltService
  ) { }

  ngOnInit(): void {
    this.woltService.getOrder(this.orderID!).subscribe(
      order => {
        this.order = order;
      }
    )
  }

}
