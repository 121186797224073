import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-options-menu-description',
  templateUrl: './options-menu-description.component.html',
  styleUrls: ['./options-menu-description.component.scss']
})
export class OptionsMenuDescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
