<div class="description">

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Podaci o WiFi mreži</div>
      </div>
      <div class="sectionDetails">Dostupni su detalji za povezivanje na bežičnu mrežu restorana, kao i
        mogućnost da gost pokaže QR kod svom društvu kako bi se i oni povezali na
        bežičnu mrežu.</div>
    </div>
  
  </div>