<div
  matDialogTitle
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  class="title"
>
  <ng-container *ngIf="title; else content">{{ title }}</ng-container>

  <a [routerLink]="[]" mat-dialog-close>
    <mat-icon class="close-dialog">close</mat-icon>
  </a>
</div>

<ng-template #content>
  <div>
    <ng-content></ng-content>
  </div>
</ng-template>
