import { OnDestroy } from '@angular/core';
import { Translation } from './../_interface/tranlsation';
import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnDestroy {
  availableTranslations = [
    {
      code: 'sr',
      name: 'Srpski'
    },
    {
      code: 'en',
      name: 'Engleski'
    }
  ];

  onDestory = new Subject<void>();

  @Input() translations?: UntypedFormArray | null;

  constructor(private fb: UntypedFormBuilder) {


  }

  languageChanged(val: any) {

  }

  ngOnDestroy(): void {
    this.onDestory.next();
    this.onDestory.complete();
  }

  getTranslation(selected: any): UntypedFormGroup {

    let el = this.translations?.controls?.find(c => c.value.language == selected) as UntypedFormGroup;
    if (!el) {
      const item: Translation = {
        'language': selected,
        'name': '',
        'description': ''
      }
      el = this.fb.group(item);
      this.translations?.push(el);
    }

    return el;

  }

}
