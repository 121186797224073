<div matDialogTitle>Odaberite načine plaćanja</div>
<mat-dialog-content>
  <div class="payments">
    <div class="item" *ngFor="let payment of selectedPaments">
      <span>{{ payment.type | paymentMethodName }}</span>
      <span>{{ payment.amount | number: "1.2-2" }}</span>
    </div>
    <div class="item remaining">
      <span> Za plaćanje</span>
      <span>{{ remaining | number: "1.2-2" }}</span>
    </div>
  </div>
  <div>
    <div class="currentAmount">
      Uplaćeno:
      <input
        [(ngModel)]="amount"
        #amountField
        (focus)="amountField.select()"
        type="number"
      />
    </div>
    <div class="paymentMethods">
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Cash)"
        [disabled]="
          (PaymentMethod.Cash | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.Cash | paymentMethodName }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.CreditCard)"
        [disabled]="
          (PaymentMethod.CreditCard | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.CreditCard | paymentMethodName }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Instant)"
        [disabled]="
          (PaymentMethod.Instant | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.Instant | paymentMethodName }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Check)"
        [disabled]="
          (PaymentMethod.Check | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.Check | paymentMethodName }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.WireTransfer)"
        [disabled]="
          (PaymentMethod.WireTransfer | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.WireTransfer | paymentMethodName }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Voucher)"
        [disabled]="
          (PaymentMethod.Voucher | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.Voucher | paymentMethodName }}
      </button>
      <button
        mat-stroked-button
        (click)="addPayment(PaymentMethod.Other)"
        [disabled]="
          (PaymentMethod.Other | isPaymentTypeEnabled | async) === false
        "
      >
        {{ PaymentMethod.Other | paymentMethodName }}
      </button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button
    mat-raised-button
    color="accent"
    (click)="voidOrders(PaymentMethod.None)"
    [disabled]="(PaymentMethod.None | isPaymentTypeEnabled | async) === false"
  >
    {{ PaymentMethod.None | paymentMethodName }}
  </button>

  <button mat-raised-button matDialogClose>Otkaži</button>
</mat-dialog-actions>
