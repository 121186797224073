import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../core/_services/authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(auth: AuthorizationService, router: Router) {

    auth.tokenLoaded.subscribe(
      val => {
        if (!val){
          return;
        }

        if (auth.isLoggedIn) {
          router.navigate(["/"]);
        }
      }
    )

   }

  ngOnInit(): void {
  }

}
