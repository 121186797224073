<app-dialog-title title="Odaberite razlog odbijanja"></app-dialog-title>
<mat-dialog-content>
  <button
    mat-stroked-button
    *ngFor="let item of rejectReasons"
    [matDialogClose]="item"
  >
    {{ item }}
  </button>
  <hr>
  <div class="customReason">
    <mat-form-field>
      <mat-label>Unesite razlog</mat-label>
      <input matInput #reason />
    </mat-form-field>
    <button mat-stroked-button [matDialogClose]="reason.value">Pošalji</button>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" matDialogClose="">Otkaži</button>
</mat-dialog-actions>
