import { SessionService } from './../../core/_services/session.service';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { WSService } from 'src/app/core/_services/ws.service';
import { UserService } from 'src/app/core/_services/user.service';
import { CompanyService } from 'src/app/core/_services/company.service';
import { AuthorizationService } from 'src/app/core/_services/authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-qr',
  templateUrl: './login-qr.component.html',
  styleUrls: ['./login-qr.component.scss']
})
export class LoginQRComponent implements OnInit, OnDestroy {
  destroyed = new Subject<void>();
  code: string | null = null;
  token: string | null = null;

  constructor(ws: WSService,
    private companyService: CompanyService,
    private auth: AuthorizationService,
    private session: SessionService
  ) {

    this.companyService.getUserCompanies().subscribe(
      list => {
        if (list.length) {
          this.session.selectedCompany.next(list[0]);
          this.getToken();

        }
      }
    )

    ws.observe("loginCompleted").subscribe(
      data => {
        if (data.data == this.token?.toLowerCase()) {
          this.getToken();
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  ngOnInit(): void {
  }

  getToken() {
    var companyID = this.session.companyID;
    this.companyService.generateSignInUrl(companyID, this.auth.currentUserID).subscribe(
      val => {
        this.token = val;
        if (val) {
          this.code = environment.backendBase.replace('app', 'konobar') + '/login/' + val;
        }
        else {
          this.code = null;
        }
      }
    )
  }
}
