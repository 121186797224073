<div class="description">

    <div class="section">
      <div class="title">
        <div class="icon"></div>
        <div class="titleText">Istorija porudžbina</div>
      </div>
      <div class="sectionDetails">Gost može da pogleda istoriju svojih porudžbina.</div>
    </div>
  
  </div>